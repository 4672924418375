<template>
   <Layout>
      <template v-if="this.$route.name == 'add-stage'">
         <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
         <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <b-form enctype="multipart/form-data" ref="stage">
                     <b-form-group id="input-group-1">
                        <label for="input-1">Stage Name
                           <span style="color: red">*</span></label>
                        <b-form-input id="input-1" v-model="form.name" placeholder="Enter Stage Name" :class="{
                           'is-invalid': submitted && $v.form.name.$error,
                        }"></b-form-input>
                        <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                           Name is required.
                        </div>
                     </b-form-group>
                     <b-form-group>
                        <b-form-checkbox v-model="form.is_active">Is Active</b-form-checkbox>
                     </b-form-group>
                     <b-button type="button" variant="success" @click="submitData">
                        <span v-if="this.$route.name == 'add-stage' && can('store-stage')">Save Data</span>
                        <span v-else-if="this.$route.name == 'edit-stage' && can('update-stage')">Update Data</span>
                     </b-button>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import stageMixin from "../../../mixins/ModuleJs/stages";
import { required } from "vuelidate/lib/validators";

export default {
   data() {
      return {
         submitted: false,
         title1: "Add Stage",
         title2: "Edit Stage",
         items: [
            {
               text: "Back",
               href: "/stage",
            },
            {
               text: "Data",
            },
         ],
      };
   },
   components: {
      Layout,
      PageHeader,
   },
   mixins: [stageMixin, MixinRequest],
   validations: {
      form: {
         name: { required },
      },
   },
};
</script>

